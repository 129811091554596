(function (angular, _, undefined) {
    "use strict";
    var links = angular.module("mikesLinks");

    links.controller("mikesLinks.ctrl", ["$scope", "$http", "$filter", "$localStorage", "LinkService",
        function ($scope, $http, $filter, $localStorage, Links) {
        var vm = this;

        vm.links = $localStorage.links || [];

        Links.getLinks()
            .then(function () {
                vm.links = $localStorage.links;
            },
            function (err) {
                console.error("Error loading links", err);
            });

        vm.dateOptions = {
            year: "numeric",
            month: "short",
            day: "numeric"
        };

        vm.bumpTarget = function (idx) {
            this.target += "x";
        };

        $scope.listFilter = [];

        $scope.search = {
            filter: [],
            placeholder: "Add Tag Filters"
        };

        $scope.$watchCollection("search.filter", function (newList, oldList) {
            $scope.listFilter = newList;
        });

    }]);

})(angular, _);
